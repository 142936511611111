import { useQuery } from '@tanstack/react-query';
import { EventsRoutes } from '@pages/routes';

const LIST_QUERY_NAME_KEY = 'events/types';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function eventTypesQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await EventsRoutes.Types.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useEventTypes(query = {}) {
  return useQuery(eventTypesQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}
