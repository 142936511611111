import { useQuery } from '@tanstack/react-query';

import { EventsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'events/parameter_sets';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function parameterSetsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await EventsRoutes.ParameterSets.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useEventsParameterSetsOptions(typeId, query = {}) {
  return useQuery(parameterSetsQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
      filter: { typeIdEq: typeId },
    },
    transform: (data) => toOptions(data.items),
    enabled: !!typeId,
  }));
}

export function useParameterSets(query = {}) {
  return useQuery(parameterSetsQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}
