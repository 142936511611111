import { useRef } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { sortBy } from 'lodash';

export function useExtendedFieldArray(options) {
  const { clearErrors, control } = useFormContext();
  const { update, remove, ...rest } = useFieldArray({ control, ...options });

  const handleDelete = (record, index) => {
    if (record.id) {
      update(index, { ...record, _destroy: true });
    } else {
      remove(index);
    }

    clearErrors();
  };

  return {
    update,
    remove,
    handleDelete,
    ...rest,
  };
}

export function useMoveArrayFieldPosition({ fields, swap, formGroupName }) {
  const latestPosition = useRef(fields.length === 0 ? 0 : fields[fields.length - 1].position);
  const { setValue } = useFormContext();

  const increasePosition = () => {
    latestPosition.current += 1;

    return latestPosition.current;
  };

  const decreasePosition = (field) => {
    latestPosition.current -= 1;

    fields.forEach((item, itemIndex) => {
      if (item.position > field.position) {
        setValue(`${formGroupName}.${itemIndex}.position`, fields[itemIndex].position - 1);
      }
    });

    return latestPosition.current;
  };

  const resetPositions = () => {
    latestPosition.current = 0;
    return 0;
  };

  const move = (direction, key) => {
    const index = fields.findIndex((field) => field.key === key);
    const item = fields[index];
    const swapIndex = fields.findIndex(({ position }) => (
      direction === 'up' ? position === item.position - 1 : position === item.position + 1
    ));
    const swapItem = fields[swapIndex];

    setValue(`${formGroupName}.${index}.position`, swapItem.position);
    setValue(`${formGroupName}.${swapIndex}.position`, item.position);
    swap(index, swapIndex);
  };

  const sortedFields = sortBy(fields, [({ position }) => position]);

  return {
    sortedFields,
    increasePosition,
    decreasePosition,
    move,
    resetPositions,
  };
}
