import React from 'react';
import { Col, Container } from 'react-bootstrap';
import { useRouter } from '@tripledotstudios/react-core';

import { Form, InUse } from '@components';
import {
  FloatingLabeledInput,
  FloatingLabeledSelect,
  FloatingLabeledDateTimeInput,
  LabeledTimePeriodField,
} from '@components/resource';
import { useAppData, useQuery } from '@hooks';
import { SegmentConfigurationSelect, WrappedSegmentsSelect } from '@components/segmentation';
import { SegmentationRoutes } from '@pages/routes';

import ConfigurationVariants from './form/ConfigurationVariants';
import { useEventTypes } from '../types/queries';

export default function ConfigurationsForm({ resource, ...props }) {
  const { enumOptions, enums, i18n: { tooltips } } = useAppData();
  const { recurring } = resource.data;
  const { query } = useRouter();
  const configurationStatuses = enums['Events::ConfigurationStatuses'];
  const configurationStatusOptions = enumOptions['Events::ConfigurationStatuses'];
  const availableConfigurationOptions = resource.data.status === configurationStatuses.PERMANENTLY_ARCHIVED
    ? configurationStatusOptions
    : configurationStatusOptions.filter(({ value }) => value !== configurationStatuses.PERMANENTLY_ARCHIVED);
  const schedulingTimeTypes = enumOptions.SchedulingTimeTypes;
  const endDateTypesOptions = enumOptions['Events::EndDateTypes'];
  const endDateTypes = enums['Events::EndDateTypes'];

  const { response: segments } = useQuery(SegmentationRoutes.Segments.indexRequest, { withoutPagination: true });
  const { data: types } = useEventTypes(query);

  if (!segments || !types) return null;

  const isInLive = resource.data.availabilityStateLive;
  const typesOptions = types.items.map(({ id, name }) => ({ label: name, value: id }));
  const variantsEvents = { handleTypeChange: () => {} };

  return segments && (
    <Form.Resource resource={resource} {...props}>
      {({ watch }) => {
        const endDateType = watch('endDateType');

        return (
          <Container fluid className="form-container">
            <Form.ControlsLayout>
              <FloatingLabeledInput label="Name" name="name" />
              <FloatingLabeledInput label="Priority" name="priority" type="number" />
              <FloatingLabeledSelect label="Status" name="status" options={availableConfigurationOptions} />
              <InUse.AvailabilityStateFormGroup floating />
              <FloatingLabeledSelect
                label="Scheduling Time Type"
                name="schedulingTimeType"
                options={schedulingTimeTypes}
              />
              {!recurring && (
                <FloatingLabeledDateTimeInput label="Preload At" name="preloadAt" />
              )}
              <FloatingLabeledDateTimeInput label="Start At" name="startAt" />
              {!recurring && (
                <FloatingLabeledSelect label="End Date" name="endDateType" options={endDateTypesOptions} />
              )}
              {endDateType === endDateTypes.CUSTOM && !recurring && (
                <FloatingLabeledDateTimeInput label="End At" name="endAt" />
              )}
              <FloatingLabeledSelect
                tooltipText={isInLive ? "Can't change while In-Live" : ''}
                disabled={isInLive}
                onChange={(args) => variantsEvents.handleTypeChange(args)}
                label="Events Type"
                name="typeId"
                options={typesOptions}
              />
              {recurring && (
                <Col className="w-100 mt-2">
                  <LabeledTimePeriodField
                    sizes={[3, 9]}
                    label="Occurrence preload offset"
                    name="preloadOffset"
                    inputs={['hours', 'minutes', 'seconds']}
                  />
                </Col>
              )}
            </Form.ControlsLayout>
            <WrappedSegmentsSelect>
              <SegmentConfigurationSelect
                segments={segments}
                tooltipText={tooltips.events.configurations.event_segment.hint}
              />
            </WrappedSegmentsSelect>
            <hr />

            <ConfigurationVariants
              variantsEvents={variantsEvents}
              types={types}
              segments={segments}
              recurring={recurring}
            />
          </Container>
        );
      }}
    </Form.Resource>
  );
}
