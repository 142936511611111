import React from 'react';
import { Link } from 'react-router-dom';

import {
  PaymentsRoutes,
  CampaignsRoutes,
  SegmentationRoutes,
  EventsRoutes,
  PiggyBanksRoutes,
  ABTestingRoutes,
} from '@pages/routes';

export default () => ({
  paymentsCatalogs: (({ id, name, applicationId }) => (
    <Link
      to={PaymentsRoutes.Catalogs.editPath({ applicationId, id })}
      key={`payment_catalog_${id}`}
      target="_blank"
    >
      {`Payment Catalog "${name}"`}
    </Link>
  )),
  paymentsProductLabels: (({ id, name, applicationId }) => (
    <Link
      to={PaymentsRoutes.ProductLabels.editPath({ applicationId, id })}
      key={`payment_product_label_${id}`}
      target="_blank"
    >
      {`Payment Product Label "${name}"`}
    </Link>
  )),
  paymentsGameProducts: (({ id, name, applicationId }) => (
    <Link
      to={PaymentsRoutes.V2.GameProducts.editPath({ applicationId, id })}
      key={`payment_game_product_${id}`}
      target="_blank"
    >
      {`Payment Game Product "${name}"`}
    </Link>
  )),
  piggyBanksConfigurations: (({ id, name, applicationId }) => (
    <Link
      to={PiggyBanksRoutes.Configurations.editPath({ applicationId, id })}
      key={`piggy_bank_configuration_${id}`}
      target="_blank"
    >
      {`PiggyBank Configuration "${name}"`}
    </Link>
  )),
  campaignsConfigurations: (({ id, name, applicationId }) => (
    <Link
      to={CampaignsRoutes.Configurations.editPath({ applicationId, id })}
      key={`campaign_configuration_${id}`}
      target="_blank"
    >
      {`Campaign Configuration "${name}"`}
    </Link>
  )),
  campaignsSequentialOffers: (({ id, name, applicationId }) => (
    <Link
      to={CampaignsRoutes.SequentialOffers.editPath({ applicationId, id })}
      key={`campaign_sequential_offer_${id}`}
      target="_blank"
    >
      {`Sequential Offer "${name}"`}
    </Link>
  )),
  segmentationSegments: (({ id, name, applicationId }) => (
    <Link
      to={SegmentationRoutes.Segments.editPath({ applicationId, id })}
      key={`segmentation_segment_${id}`}
      target="_blank"
    >
      {`Segmentation Segment "${name}"`}
    </Link>
  )),
  eventsParameterSets: (({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.ParameterSets.editPath({ applicationId, id })}
      key={`parameter_set_${id}`}
      target="_blank"
    >
      {`Parameter Set "${name}"`}
    </Link>
  )),
  eventsObjectiveConfigurations: (({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.ObjectiveConfigurations.editPath({ applicationId, id })}
      key={`objective_configuration_${id}`}
      target="_blank"
    >
      {`Objective Configuration "${name}"`}
    </Link>
  )),
  eventsObjectiveTemplates: (({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.ObjectiveTemplates.editPath({ applicationId, id })}
      key={`objective_template_${id}`}
      target="_blank"
    >
      {`Objective Template "${name}"`}
    </Link>
  )),
  eventsConfigurations: (({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.Configurations.editPath({ applicationId, id })}
      key={`event_configuration_${id}`}
      target="_blank"
    >
      {`Event Configuration "${name}"`}
    </Link>
  )),
  eventsTypes: (({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.Types.editPath({ applicationId, id })}
      key={`event_type_${id}`}
      target="_blank"
    >
      {`Event Type "${name}"`}
    </Link>
  )),
  abTestingExperiments: ({ id, name, applicationId }) => (
    <Link
      to={ABTestingRoutes.Experiments.editPath({ applicationId, id })}
      key={`abtesting_experiment_${id}`}
      target="_blank"
    >
      {`A/B Testing Experiment "${name}"`}
    </Link>
  ),
  eventsGachaChests: ({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.GachaChests.editPath({ applicationId, id })}
      key={`events_gacha_chest_${id}`}
      target="_blank"
    >
      {`Events Gacha Chest "${name}"`}
    </Link>
  ),
  eventsCollectionSets: ({ id, name, applicationId }) => (
    <Link
      to={EventsRoutes.CollectionSets.editPath({ applicationId, id })}
      key={`events_collection_set_${id}`}
      target="_blank"
    >
      {`Events Collection Set "${name}"`}
    </Link>
  ),
});
