import React from 'react';
import { Table } from 'react-bootstrap';
import { FormGroup } from '@tripledotstudios/react-core';
import { useWatch } from 'react-hook-form';

import {
  useAppData, useExtendedFieldArray, useFormPermissions, useCurrentApplication, useMoveArrayFieldPosition,
} from '@hooks';
import { Form, Button } from '@components';
import {
  Input, Select, PositionControl, RegisteredError,
} from '@components/resource';

import { useGameProductsOptions } from '@pages/payments/game_products/queries';
import { useStoreProductsOptions } from '@pages/payments/v2/store_products/queries';

const gameProductsPriceTypes = ['store_product', 'ads', 'free'];

const Position = ({ itemPath }) => useWatch({ name: `${itemPath}.position` });

export default function SequentialOfferItems({ inUse }) {
  const { readOnly } = useFormPermissions();
  const editable = !readOnly && !inUse;

  const currentApplication = useCurrentApplication();

  const priceTypesEnum = useAppData().enums['Payments::PriceTypes'];
  const priceTypes = useAppData().enumOptions['Payments::PriceTypes'].filter((priceType) => (
    gameProductsPriceTypes.includes(priceType.value)
  ));

  const gameProductsQuery = useGameProductsOptions({ applicationId: currentApplication.id });
  const storeProductsQuery = useStoreProductsOptions({ applicationId: currentApplication.id });

  const formGroupName = 'sequentialOfferItemsAttributes';
  const {
    fields, append, handleDelete, swap,
  } = useExtendedFieldArray({ name: formGroupName, keyName: 'key' });
  const {
    sortedFields,
    increasePosition,
    decreasePosition,
    move,
  } = useMoveArrayFieldPosition({ fields, swap, formGroupName });

  const appendItem = () => append({ priceType: priceTypesEnum.STORE_PRODUCT, position: increasePosition() });

  const deleteItem = (field, index) => {
    decreasePosition(field);

    handleDelete(field, index);
  };

  const isSomeLoading = [gameProductsQuery, storeProductsQuery].some(({ isLoading }) => isLoading);

  if (isSomeLoading) return null;

  return (
    <>
      <RegisteredError name="base" />
      {fields.filter(({ _destroy }) => !_destroy).length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th />
              <th>Position</th>
              <th>Game Product</th>
              <th>Price Type</th>
              <th>Price</th>
              {editable && <th width="10%">Action</th>}
            </tr>
          </thead>
          <tbody>
            {sortedFields.map((item, index) => {
              if (item._destroy) {
                return null;
              }
              const itemPath = `${formGroupName}.${index}`;
              const fieldDisabled = inUse && item.id;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <FormGroup key={`${item.key}.${item.position}.${index}`} name={itemPath}>
                  <tr>
                    <td>
                      {editable && (
                        <div className="grid align-items-center gap-0" style={{ '--bs-columns': 2 }}>
                          <PositionControl
                            onUp={() => move('up', item.key)}
                            onDown={() => move('down', item.key)}
                            disableUp={item.position === 1}
                            disableDown={item.position === fields.length}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <Input hidden name="position" readOnly disabled type="number" />
                      <Position itemPath={itemPath} />
                    </td>
                    <td>
                      <Select name="productId" disabled={fieldDisabled} options={gameProductsQuery.data} isSearchable />
                    </td>
                    <td>
                      <Select name="priceType" disabled={fieldDisabled} options={priceTypes} />
                    </td>
                    <td>
                      <Form.When name="priceType" value={priceTypesEnum.STORE_PRODUCT}>
                        <Select
                          name="storeProductId"
                          disabled={fieldDisabled}
                          options={storeProductsQuery.data}
                          isSearchable
                        />
                      </Form.When>
                    </td>
                    {editable && (
                      <td><Button.Delete title="Remove" onClick={() => deleteItem(item, index)} /></td>
                    )}
                  </tr>
                </FormGroup>
              );
            })}
          </tbody>
        </Table>
      )}
      {editable && <Button.Add title="Add item" onClick={appendItem} />}
    </>
  );
}
